 svg .svg-elem-1 {
  stroke-dashoffset: 850.015380859375px;
  stroke-dasharray: 850.015380859375px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
                        fill 0.2s ease 0.2s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 0s,
                fill 0.2s ease 0.2s;
}

svg.active .svg-elem-1 {
  stroke-dashoffset: 1700.03076171875px;
  fill: rgb(100, 140, 201);
}

svg .svg-elem-2 {
  stroke-dashoffset: 850.0171508789062px;
  stroke-dasharray: 850.0171508789062px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.1s,
                        fill 0.2s ease 0.30000000000000004s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.1s,
                fill 0.2s ease 0.30000000000000004s;
}

svg.active .svg-elem-2 {
  stroke-dashoffset: 1700.0343017578125px;
  fill: rgb(100, 140, 201);
}

svg .svg-elem-3 {
  stroke-dashoffset: 850.0189208984375px;
  stroke-dasharray: 850.0189208984375px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s,
                        fill 0.2s ease 0.4s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s,
                fill 0.2s ease 0.4s;
}

svg.active .svg-elem-3 {
  stroke-dashoffset: 1700.037841796875px;
  fill: rgb(100, 140, 201);
}

svg .svg-elem-4 {
  stroke-dashoffset: 850.0137939453125px;
  stroke-dasharray: 850.0137939453125px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.30000000000000004s,
                        fill 0.2s ease 0.5s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.30000000000000004s,
                fill 0.2s ease 0.5s;
}

svg.active .svg-elem-4 {
  stroke-dashoffset: 1700.027587890625px;
  fill: rgb(100, 140, 201);
}

svg .svg-elem-5 {
  stroke-dashoffset: 850.0146484375px;
  stroke-dasharray: 850.0146484375px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s,
                        fill 0.2s ease 0.6000000000000001s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s,
                fill 0.2s ease 0.6000000000000001s;
}

svg.active .svg-elem-5 {
  stroke-dashoffset: 1700.029296875px;
  fill: rgb(100, 140, 201);
}

svg .svg-elem-6 {
  stroke-dashoffset: 850.016357421875px;
  stroke-dasharray: 850.016357421875px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s,
                        fill 0.2s ease 0.7s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s,
                fill 0.2s ease 0.7s;
}

svg.active .svg-elem-6 {
  stroke-dashoffset: 1700.03271484375px;
  fill: rgb(100, 140, 201);
}

svg .svg-elem-7 {
  stroke-dashoffset: 462.8460505470795px;
  stroke-dasharray: 462.8460505470795px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6000000000000001s,
                        fill 0.2s ease 0.8s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6000000000000001s,
                fill 0.2s ease 0.8s;
}

svg.active .svg-elem-7 {
  stroke-dashoffset: 925.692101094159px;
  fill: rgb(225, 231, 240);
}

svg .svg-elem-8 {
  stroke-dashoffset: 459.467529296875px;
  stroke-dasharray: 459.467529296875px;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.7000000000000001s,
                        fill 0.2s ease 0.9000000000000001s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.7000000000000001s,
                fill 0.2s ease 0.9000000000000001s;
}

svg.active .svg-elem-8 {
  stroke-dashoffset: 918.93505859375px;
}

svg .svg-elem-9 {
  stroke-dashoffset: 655.7756958007812px;
  stroke-dasharray: 655.7756958007812px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s,
                        fill 0.2s ease 1s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s,
                fill 0.2s ease 1s;
}

svg.active .svg-elem-9 {
  stroke-dashoffset: 1311.5513916015625px;
  fill: rgb(0, 0, 0);
}

svg .svg-elem-10 {
  stroke-dashoffset: 71.18640136998266px;
  stroke-dasharray: 71.18640136998266px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s,
                        fill 0.2s ease 1.1s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 0.9s,
                fill 0.2s ease 1.1s;
}

svg.active .svg-elem-10 {
  stroke-dashoffset: 142.37280273996532px;
  fill: rgb(49, 130, 206);
}

svg .svg-elem-11 {
  stroke-dashoffset: 28.243736267089844px;
  stroke-dasharray: 28.243736267089844px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 1s,
                        fill 0.2s ease 1.2s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 1s,
                fill 0.2s ease 1.2s;
}

svg.active .svg-elem-11 {
  stroke-dashoffset: 56.48747253417969px;
  fill: rgb(255, 255, 255);
}

svg .svg-elem-12 {
  stroke-dashoffset: 462.8460505470795px;
  stroke-dasharray: 462.8460505470795px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s,
                        fill 0.2s ease 1.3s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.1s,
                fill 0.2s ease 1.3s;
}

svg.active .svg-elem-12 {
  stroke-dashoffset: 925.692101094159px;
  fill: rgb(225, 231, 240);
}

svg .svg-elem-13 {
  stroke-dashoffset: 459.4678955078125px;
  stroke-dasharray: 459.4678955078125px;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s,
                        fill 0.2s ease 1.4000000000000001s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2000000000000002s,
                fill 0.2s ease 1.4000000000000001s;
}

svg.active .svg-elem-13 {
  stroke-dashoffset: 918.935791015625px;
}

svg .svg-elem-14 {
  stroke-dashoffset: 413.5766296386719px;
  stroke-dasharray: 413.5766296386719px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s,
                        fill 0.2s ease 1.5s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.3s,
                fill 0.2s ease 1.5s;
}

svg.active .svg-elem-14 {
  stroke-dashoffset: 827.1532592773438px;
  fill: rgb(0, 0, 0);
}

svg .svg-elem-15 {
  stroke-dashoffset: 71.18640136998266px;
  stroke-dasharray: 71.18640136998266px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s,
                        fill 0.2s ease 1.6s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4000000000000001s,
                fill 0.2s ease 1.6s;
}

svg.active .svg-elem-15 {
  stroke-dashoffset: 142.37280273996532px;
  fill: rgb(49, 130, 206);
}

svg .svg-elem-16 {
  stroke-dashoffset: 42.882423400878906px;
  stroke-dasharray: 42.882423400878906px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s,
                        fill 0.2s ease 1.7s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s,
                fill 0.2s ease 1.7s;
}

svg.active .svg-elem-16 {
  stroke-dashoffset: 85.76484680175781px;
  fill: rgb(255, 255, 255);
}

svg .svg-elem-17 {
  stroke-dashoffset: 462.8460505470795px;
  stroke-dasharray: 462.8460505470795px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s,
                        fill 0.2s ease 1.8s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s,
                fill 0.2s ease 1.8s;
}

svg.active .svg-elem-17 {
  stroke-dashoffset: 925.692101094159px;
  fill: rgb(225, 231, 240);
}

svg .svg-elem-18 {
  stroke-dashoffset: 459.4678649902344px;
  stroke-dasharray: 459.4678649902344px;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s,
                        fill 0.2s ease 1.9000000000000001s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s,
                fill 0.2s ease 1.9000000000000001s;
}

svg.active .svg-elem-18 {
  stroke-dashoffset: 918.9357299804688px;
}

svg .svg-elem-19 {
  stroke-dashoffset: 735.105224609375px;
  stroke-dasharray: 735.105224609375px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s,
                        fill 0.2s ease 2s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s,
                fill 0.2s ease 2s;
}

svg.active .svg-elem-19 {
  stroke-dashoffset: 1470.21044921875px;
  fill: rgb(0, 0, 0);
}

svg .svg-elem-20 {
  stroke-dashoffset: 71.18640136998266px;
  stroke-dasharray: 71.18640136998266px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s,
                        fill 0.2s ease 2.1s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s,
                fill 0.2s ease 2.1s;
}

svg.active .svg-elem-20 {
  stroke-dashoffset: 142.37280273996532px;
  fill: rgb(49, 130, 206);
}

svg .svg-elem-21 {
  stroke-dashoffset: 45.21706771850586px;
  stroke-dasharray: 45.21706771850586px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 2s,
                        fill 0.2s ease 2.2s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 2s,
                fill 0.2s ease 2.2s;
}

svg.active .svg-elem-21 {
  stroke-dashoffset: 90.43413543701172px;
  fill: rgb(255, 255, 255);
}

svg .svg-elem-22 {
  stroke-dashoffset: 462.8460505470795px;
  stroke-dasharray: 462.8460505470795px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s,
                        fill 0.2s ease 2.3000000000000003s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s,
                fill 0.2s ease 2.3000000000000003s;
}

svg.active .svg-elem-22 {
  stroke-dashoffset: 925.692101094159px;
  fill: rgb(225, 231, 240);
}

svg .svg-elem-23 {
  stroke-dashoffset: 459.46771240234375px;
  stroke-dasharray: 459.46771240234375px;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s,
                        fill 0.2s ease 2.4000000000000004s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s,
                fill 0.2s ease 2.4000000000000004s;
}

svg.active .svg-elem-23 {
  stroke-dashoffset: 918.9354248046875px;
}

svg .svg-elem-24 {
  stroke-dashoffset: 665.4996337890625px;
  stroke-dasharray: 665.4996337890625px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3000000000000003s,
                        fill 0.2s ease 2.5000000000000004s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3000000000000003s,
                fill 0.2s ease 2.5000000000000004s;
}

svg.active .svg-elem-24 {
  stroke-dashoffset: 1330.999267578125px;
  fill: rgb(0, 0, 0);
}

svg .svg-elem-25 {
  stroke-dashoffset: 71.18640136998266px;
  stroke-dasharray: 71.18640136998266px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4000000000000004s,
                        fill 0.2s ease 2.6000000000000005s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4000000000000004s,
                fill 0.2s ease 2.6000000000000005s;
}

svg.active .svg-elem-25 {
  stroke-dashoffset: 142.37280273996532px;
  fill: rgb(49, 130, 206);
}

svg .svg-elem-26 {
  stroke-dashoffset: 54.403873443603516px;
  stroke-dasharray: 54.403873443603516px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s,
                        fill 0.2s ease 2.7s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s,
                fill 0.2s ease 2.7s;
}

svg.active .svg-elem-26 {
  stroke-dashoffset: 108.80774688720703px;
  fill: rgb(255, 255, 255);
}

svg .svg-elem-27 {
  stroke-dashoffset: 462.8460505470795px;
  stroke-dasharray: 462.8460505470795px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s,
                        fill 0.2s ease 2.8000000000000003s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s,
                fill 0.2s ease 2.8000000000000003s;
}

svg.active .svg-elem-27 {
  stroke-dashoffset: 925.692101094159px;
  fill: rgb(225, 231, 240);
}

svg .svg-elem-28 {
  stroke-dashoffset: 459.46771240234375px;
  stroke-dasharray: 459.46771240234375px;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.7s,
                        fill 0.2s ease 2.9000000000000004s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.7s,
                fill 0.2s ease 2.9000000000000004s;
}

svg.active .svg-elem-28 {
  stroke-dashoffset: 918.9354248046875px;
}

svg .svg-elem-29 {
  stroke-dashoffset: 725.8348999023438px;
  stroke-dasharray: 725.8348999023438px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.8000000000000003s,
                        fill 0.2s ease 3.0000000000000004s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.8000000000000003s,
                fill 0.2s ease 3.0000000000000004s;
}

svg.active .svg-elem-29 {
  stroke-dashoffset: 1451.6697998046875px;
  fill: rgb(0, 0, 0);
}

svg .svg-elem-30 {
  stroke-dashoffset: 71.18640136998266px;
  stroke-dasharray: 71.18640136998266px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.9000000000000004s,
                        fill 0.2s ease 3.1000000000000005s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 2.9000000000000004s,
                fill 0.2s ease 3.1000000000000005s;
}

svg.active .svg-elem-30 {
  stroke-dashoffset: 142.37280273996532px;
  fill: rgb(49, 130, 206);
}

svg .svg-elem-31 {
  stroke-dashoffset: 47.993385314941406px;
  stroke-dasharray: 47.993385314941406px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 3s,
                        fill 0.2s ease 3.2s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 3s,
                fill 0.2s ease 3.2s;
}

svg.active .svg-elem-31 {
  stroke-dashoffset: 95.98677062988281px;
  fill: rgb(255, 255, 255);
}

svg .svg-elem-32 {
  stroke-dashoffset: 462.8460505470795px;
  stroke-dasharray: 462.8460505470795px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.1s,
                        fill 0.2s ease 3.3000000000000003s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.1s,
                fill 0.2s ease 3.3000000000000003s;
}

svg.active .svg-elem-32 {
  stroke-dashoffset: 925.692101094159px;
  fill: rgb(225, 231, 240);
}

svg .svg-elem-33 {
  stroke-dashoffset: 459.46746826171875px;
  stroke-dasharray: 459.46746826171875px;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.2s,
                        fill 0.2s ease 3.4000000000000004s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.2s,
                fill 0.2s ease 3.4000000000000004s;
}

svg.active .svg-elem-33 {
  stroke-dashoffset: 918.9349365234375px;
}

svg .svg-elem-34 {
  stroke-dashoffset: 292.90826416015625px;
  stroke-dasharray: 292.90826416015625px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.3000000000000003s,
                        fill 0.2s ease 3.5000000000000004s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.3000000000000003s,
                fill 0.2s ease 3.5000000000000004s;
}

svg.active .svg-elem-34 {
  stroke-dashoffset: 585.8165283203125px;
  fill: rgb(0, 0, 0);
}

svg .svg-elem-35 {
  stroke-dashoffset: 71.18640136998266px;
  stroke-dasharray: 71.18640136998266px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.4000000000000004s,
                        fill 0.2s ease 3.6000000000000005s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.4000000000000004s,
                fill 0.2s ease 3.6000000000000005s;
}

svg.active .svg-elem-35 {
  stroke-dashoffset: 142.37280273996532px;
  fill: rgb(49, 130, 206);
}

svg .svg-elem-36 {
  stroke-dashoffset: 47.55519485473633px;
  stroke-dasharray: 47.55519485473633px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.5s,
                        fill 0.2s ease 3.7s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.5s,
                fill 0.2s ease 3.7s;
}

svg.active .svg-elem-36 {
  stroke-dashoffset: 95.11038970947266px;
  fill: rgb(255, 255, 255);
}

svg .svg-elem-37 {
  stroke-dashoffset: 1679.898681640625px;
  stroke-dasharray: 1679.898681640625px;
  fill: transparent;
  -webkit-transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.6s,
                        fill 0.2s ease 3.8000000000000003s;
          transition: stroke-dashoffset 0.1s cubic-bezier(0.47, 0, 0.745, 0.715) 3.6s,
                fill 0.2s ease 3.8000000000000003s;
}

svg.active .svg-elem-37 {
  stroke-dashoffset: 3359.79736328125px;
  fill: rgb(49, 130, 206);
}
