main {
  margin-left: 5rem;
  padding: 1rem;

}

/* Container */
.navbar {
  z-index: 99999999;
  position: fixed;
  transition: width 600ms ease;
  overflow: hidden;
  border-right: solid 1px rgba(150, 156, 186, 0.1);
}

/*Navigate*/
.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.nav-item {
  width: 100%;
}

.nav-item:last-child {
  margin-top: auto;
  border-top: solid 2px rgba(150, 156, 186, 0.2);
}

.nav-header {
  display: flex;
  align-items: center;
  height: 4rem;
  text-decoration: none;
  filter: grayscale(100%) opacity(0.7);
  transition: var(--transition-speed);
}

.nav-header:hover {
  filter: grayscale(0%) opacity(1);
  bottom: calc(100% + 12px);
  left: 43%;
  transform: translateX(5%);
  color: #3182ce;
}

/* a */
.nav-link {
  display: flex;
  align-items: center;
  height: 3rem;
  max-width: 2rem;
  text-decoration: none;
  filter: grayscale(100%) opacity(1);
  transition: var(--transition-speed);
  border: none;
}

.nav-link:hover {
  filter: grayscale(0%) opacity(1);
  bottom: calc(100% + 12px);
  left: 43%;
  transform: translateX(5%);
  color: #3182ce;
  transition: var(--transition-speed);
}

.link-text {
  font-size: 14px;
  display: none;
  white-space: nowrap;
  margin-left: 1rem;
}

.nav-link svg {
  width: 2rem;
  min-width: 1rem;
  margin: 0 1.5rem;
}

.nav-header svg {
  width: 2rem;
  min-width: 1rem;
  margin: 0 1.5rem;
}

.fa-primary {
  color: #3182ce;
}

.fa-secondary {
  color: #3182ce;
}

.fa-primary,
.fa-secondary {
  transition: var(--transition-speed);
}

/*NavLogo*/
.logo {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
  text-align: center;
  color: var(--text-secondary);
  letter-spacing: 0.3ch;
  width: 100%;
}

.img-logo {
  width: 150px;
  height: 60px;
}

.logo svg {
  transform: rotate(0deg);
  transition: var(--transition-speed);
}

.logo-text {
  display: inline;
  position: absolute;
  left: -999px;
  transition: var(--transition-speed);
}

.navbar:hover .logo svg {
  transform: rotate(-180deg);
}

/* Small screens */
@media only screen and (max-width: 600px) {
  .navbar {
    bottom: 0;
    width: 100vw;
    height: 5rem;
  }

  .logo {
    display: none;
  }

  .navbar-nav {
    flex-direction: row;
  }

  .nav-link {
    justify-content: center;
  }

  main {
    margin: 0;
  }
}

/* Large screens */
@media only screen and (min-width: 600px) {
  .navbar {
    top: 0;
    width: 4rem;
    height: 100vh;
  }

  .navbar:hover {
    width: 19rem;
  }

  .navbar:hover .link-text {
    display: inline;
  }

  .navbar:hover .logo svg {
    margin-left: 11rem;
  }

  .navbar:hover .logo-text {
    left: 0px;
  }
}

.dark {
  --text-primary: #b6b6b6;
  --text-secondary: #ececec;
  --bg-primary: #23232e;
  --bg-secondary: #141418;
}

.light {
  --text-primary: #1f1f1f;
  --text-secondary: #000000;
  --bg-primary: #ffffff;
  --bg-secondary: #e4e4e4;
}

.solar {
  --text-primary: #576e75;
  --text-secondary: #35535c;
  --bg-primary: #fdf6e3;
  --bg-secondary: #f5e5b8;
}

.theme-icon {
  display: none;
}

.dark #darkIcon {
  display: block;
}

.light #lightIcon {
  display: block;
}

.solar #solarIcon {
  display: block;
}
